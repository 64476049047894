
import getEnv from '../config'

export const APP_NAME = 'azores-cti'

export const OTEL_API_URL = getEnv('OTEL_API_URL')
export const OTEL_API_TOKEN = getEnv('OTEL_API_TOKEN')
export const OTEL_DELAY_MS = parseInt(getEnv('OTEL_DELAY_MS', 60000))
export const OTEL_METRICS_INTERVAL_MS = parseInt(getEnv('OTEL_METRICS_INTERVAL_MS', 5000))
export const OTEL_BATCH_SIZE = parseInt(getEnv('OTEL_BATCH_SIZE', 2048))
