export const CTI_RTM_OVER_PUSHER = 'CTI_RTM_OVER_PUSHER'
export const CTI_CLIENT_AUTOMATIC_ACCOUNT_LOGIN = 'CTI_CLIENT_AUTOMATIC_ACCOUNT_LOGIN'
export const CTI_CLIENT_CROSS_AGENT_PROTECTION = 'CTI_CLIENT_CROSS_AGENT_PROTECTION'
export const CTI_CLIENT_SINGLE_SIGN_OUT = 'CTI_CLIENT_SINGLE_SIGN_OUT'
export const CTI_NOTIFY_SALESFORCE_ON_LOGIN = 'CTI_NOTIFY_SALESFORCE_ON_LOGIN'
export const DEFAULT_ACTIVE_FF = [CTI_CLIENT_SINGLE_SIGN_OUT]
export const FALLBACK_FF_ON_SPLITIO_FAIL = []

export const FF_WHITE_LIST = [
  'CTI_rtm_over_pusher',
  'CTI_CLIENT_automatic_account_login',
  'CTI_CLIENT_cross_agent_protection',
  'CTI_CLIENT_single_sign_out',
  'CTI_NOTIFY_SALESFORCE_ON_LOGIN'
]
