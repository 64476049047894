import {
  createMonitor,
  createWebTracerProvider,
  createWebMeterProvider,
  instrument,
  getTracer,
  getMeter
} from '@atlas/otlp'

import {
  APP_NAME,
  OTEL_API_URL,
  OTEL_API_TOKEN,
  OTEL_DELAY_MS,
  OTEL_METRICS_INTERVAL_MS
} from '../constants'

export class DynatraceMonitoringProvider {
  constructor (customAttributes = {}) {
    this._attributes = {}

    const monitorConfig = {
      url: OTEL_API_URL,
      token: OTEL_API_TOKEN,
      resource: {
        name: APP_NAME
      },
      metrics: {
        interval: OTEL_METRICS_INTERVAL_MS
      },
      processor: {
        delay: OTEL_DELAY_MS
      },
      attributes: {
        ...customAttributes
      },
      instrumentations: {
        '@opentelemetry/instrumentation-user-interaction': {
          enabled: false
        },
        '@opentelemetry/instrumentation-xml-http-request': {
          enabled: false
        },
        '@opentelemetry/instrumentation-fetch': {
          enabled: false
        },
        '@atlas/instrumentation-uncaught-errors': {
          attributes: this._attributes
        },
        '@opentelemetry/instrumentation-document-load': {
          enabled: false
        }
      }
    }

    if (window.ws_monitor) {
      window.ws_monitor.configure(monitorConfig)
      this._monitor = window.ws_monitor
    } else {
      const tracerProvider = createWebTracerProvider(monitorConfig)
      const tracer = getTracer(tracerProvider, monitorConfig)

      const meterProvider = createWebMeterProvider(monitorConfig)
      const meter = getMeter(meterProvider, monitorConfig)

      this._monitor = createMonitor(tracer, meter)

      instrument(this._monitor, monitorConfig.instrumentations, {
        tracerProvider,
        meterProvider
      })
    }
  }

  report (error, attributes) {
    this._monitor.report(error, {
      ...attributes,
      ...this._attributes
    })
  }

  dispatch (eventName, attributes) {
    this._monitor.dispatch(eventName, {
      ...attributes,
      ...this._attributes
    })
  }

  measure (measure, payload, options) {
    this._monitor.measure(measure, payload, options)
  }

  setAttributes () {
    // The default attributes are not set by this method, they are included
    // in the initial configuration.
    // For that reason, we don't have to do anything here.
  }

  addAttributes (attributes) {
    const keys = Object.keys(attributes)

    for (const key of keys) {
      if (!this._attributes[key]) {
        this._attributes[key] = attributes[key]
      }
    }
  }
}
