import { DynatraceLoggerProvider } from './dynatrace.otlp.logger'

let loggerInstance
export const initLoggerProvider = (customAttributes) => {
  if (!loggerInstance) {
    loggerInstance = new DynatraceLoggerProvider(customAttributes)
  }
}

export const logInfo = (message, details) => {
  getInstance()?.info(message, details)
}

export const logError = (message, details) => {
  getInstance()?.error(message, details)
}

export const logDebug = (message, details) => {
  getInstance()?.debug(message, details)
}

export const logWarn = (message, details) => {
  getInstance()?.warn(message, details)
}

const getInstance = () => {
  if (!loggerInstance) {
    console.error('Logger instance is not initialized!')
    return
  }

  return loggerInstance
}
