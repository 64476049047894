import { createLogger } from '@atlas/otlp'
import { SeverityNumber } from '@opentelemetry/api-logs'
import {
  APP_NAME,
  OTEL_API_URL,
  OTEL_API_TOKEN,
  OTEL_BATCH_SIZE,
  OTEL_DELAY_MS
} from '../constants'

const OTEL_STATUS_CODE_ATTRIBUTE = 'otel.status_code'

export class DynatraceLoggerProvider {
  constructor (customAttributes = {}) {
    const loggerConfig = {
      url: OTEL_API_URL,
      token: OTEL_API_TOKEN,
      resource: {
        name: APP_NAME
      },
      processor: {
        delay: OTEL_DELAY_MS,
        maxBatchSize: OTEL_BATCH_SIZE
      },
      attributes: {
        ...customAttributes
      }
    }

    try {
      this._logger = createLogger(loggerConfig)
    } catch (error) {
      console.error('Error creating dynatrace logger', error)
    }

    if (!this._logger) {
      console.error('Dynatrace logger is not initialized!')
    }
  }

  info (message, details = {}) {
    this._logger?.emit({
      body: message,
      attributes: details,
      severityNumber: SeverityNumber.INFO,
      severityText: 'INFO'
    })
  }

  error (message, details = {}) {
    this._logger?.emit({
      body: message,
      attributes: details,
      severityNumber: SeverityNumber.ERROR,
      severityText: 'ERROR',
      [OTEL_STATUS_CODE_ATTRIBUTE]: 'ERROR'
    })
  }

  debug (message, details = {}) {
    this._logger?.emit({
      body: message,
      attributes: details,
      severityNumber: SeverityNumber.DEBUG,
      severityText: 'DEBUG'
    })
  }

  warn (message, details = {}) {
    this._logger?.emit({
      body: message,
      attributes: details,
      severityNumber: SeverityNumber.WARN,
      severityText: 'WARN'
    })
  }
}
