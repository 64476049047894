import { logError } from './logger/logger'
import { notifyError } from './monitoring/notifier'

export const logAndNotifyError = (error, attributes) => {
  logError(error, attributes)
  notifyError(error, attributes)
}

export const getErrorMessage = (error) => {
  if (error instanceof Error) {
    return error.message
  }

  return JSON.stringify(error, null, 2)
}
