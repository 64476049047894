import { DynatraceMonitoringProvider } from './dynatrace.otlp.monitoring'

let monitorInstance
export const initMonitoringProvider = (customAttributes) => {
  if (!monitorInstance) {
    monitorInstance = new DynatraceMonitoringProvider(customAttributes)
  }
}

export const notifyError = (errorAction, attributes) => {
  getInstance()?.report(errorAction, attributes)
}

export const notifyAction = (name, attributes) => {
  getInstance()?.dispatch(name, attributes)
}

export const setCustomAttributes = (attributes) => {
  getInstance()?.addAttributes(attributes)
}

const getInstance = () => {
  if (!monitorInstance) {
    console.error('Monitor instance is not initialized!')
    return
  }

  return monitorInstance
}
