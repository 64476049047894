import React, { useEffect, useRef } from 'react'
import { PropTypes } from 'prop-types'
import './Input.scss'

const Input = ({ onChange, onEnter, value }) => {
  const textInputRef = useRef(null)

  useEffect(() => {
    if (textInputRef.current && textInputRef.current.textContent !== value) {
      textInputRef.current.textContent = value
    }
  }, [value])

  const handleOnClick = (e) => {
    e.preventDefault()
    placeCaretAtEnd(textInputRef.current)
  }

  const handleOnChange = (e) => {
    const newValue = e.target.textContent
    onChange(newValue)
  }

  const placeCaretAtEnd = (el) => {
    el.focus()
    if (typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined') {
      const range = document.createRange()
      range.selectNodeContents(el)
      range.collapse(false)
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange()
      textRange.moveToElementText(el)
      textRange.collapse(false)
      textRange.select()
    }
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onEnter()
    }
  }

  return (
    <div className='container'>
      <div className='label'>
        <span>Domain name</span>
      </div>
      <div className='div-container' onClick={handleOnClick}>
        <div className='input-container'>
          <span
            className='input'
            contentEditable='true'
            onKeyPress={handleEnter}
            onInput={handleOnChange}
            ref={textInputRef}
          />
          <span className='placeholder'>.{__WEB_APP_DOMAIN__}</span>
        </div>
      </div>
    </div>
  )
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default Input
