import SalesforceAdaptor from './salesforce.adaptor'
import getApex from './salesforce/apex'
import { formatTo18, getContactId, VisibilityController } from './salesforce'
import { CTI_NOTIFY_SALESFORCE_ON_LOGIN } from '../featureflags/whitelist'

export const checkSuccess = (callback) => (response) => { callback(response.success) }
export const isVisible = (callback) => (response) => {
  if (!response.success) return

  const isVisible = response.returnValue.visible

  callback(isVisible)
}

class SalesforceLightningAdaptor extends SalesforceAdaptor {
  importVendor () {
    const vendor = require('../lib/sforce.opencti').default
    require('../lib/sforce.console')

    return Promise.resolve(vendor)
  }

  /*
  * Hooks implementation
  */
  onVendorReady (vendor) {
    this.runApex = getApex(vendor)
    vendor.opencti.enableClickToDial()
  }

  onAdaptorReady (vendor, mp, capabilities) {
    this.initTimestamp = Date.now()
    vendor.opencti.onClickToDial({
      listener: (event) => {
        this.clickToCall(event, mp)
      }
    })

    vendor.opencti.hvs.onWorkStart({
      listener: (event) => {
        this.onWorkStart(event)
      }
    })

    this.visibilityController = new VisibilityController(
      (callback) => {
        this.checkIsVisible(callback, vendor)
      },
      (newVisibility, callback) => {
        this.setVisible(newVisibility, callback, vendor)
      })

    this.visibilityController.onVisibilityChange((isCtiVisible) => {
      this.changeCallbarVisibility(isCtiVisible, mp)
    })

    if (capabilities.presence) {
      this._setOmnichannelListeners(vendor, mp)
    }

    if (this.isFeatureActive(CTI_NOTIFY_SALESFORCE_ON_LOGIN)) {
      this.notifyLoginToSalesforce()
    }
  }

  onOpenContact (contact, vendor) {
    const salesforceId = getContactId(contact)

    vendor.opencti.screenPop({
      type: vendor.opencti.SCREENPOP_TYPE.SOBJECT,
      params: { recordId: salesforceId }
    })
  }

  onOpenTicket (externalId, vendor) {
    vendor.opencti.screenPop({
      type: vendor.opencti.SCREENPOP_TYPE.SOBJECT,
      params: { recordId: externalId }
    })
  }

  onHvsCallEnd (hvsData, vendor) {
    vendor.opencti.hvs.completeWork({
      workId: hvsData.work_item_id,
      attributes: {
        disposition: hvsData.disposition,
        taskId: hvsData.task_id,
        wasConnected: hvsData.was_connected
      }
    })
  }

  /*
  * Internal API
  */
  checkIsVisible (callback, vendor) {
    vendor.opencti.isSoftphonePanelVisible({
      callback: isVisible(callback)
    })
  }

  setVisible (newVisibility, callback, vendor) {
    vendor.opencti.setSoftphonePanelVisibility({
      visible: newVisibility,
      callback: checkSuccess(callback)
    })
  }

  applyHeight (height, vendor) {
    // Only numeric values from 240 through 700 are valid.
    vendor.opencti.setSoftphonePanelHeight({ heightPX: height < 240 ? 240 : height })
  }

  showCTIPopover (vendor, height = 300) {
    this.applyHeight(height, vendor)
    this.setVisible(true, () => {}, vendor)
  }

  hideCTIPopover (vendor) {
    this.setVisible(false, () => {}, vendor)
  }

  getCaseIdForCurrentPage (vendor) {
    return new Promise((resolve, reject) => {
      this.getPageInfo(vendor, (response) => {
        if (!response.success) {
          const error = response.errors[0]

          return reject(new Error(error.description))
        }

        const info = response.returnValue

        if (info.objectType === 'Case') {
          resolve({ [this.name]: formatTo18(info.recordId) })
        } else {
          reject(new Error('Salesforce page is not a Case.'))
        }
      })
    })
  }

  getPageInfo (vendor, callback) {
    vendor.opencti.getAppViewInfo({ callback })
  }
}

export default SalesforceLightningAdaptor