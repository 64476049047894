/* global accountName, accountId */

import login from './cti-app'
import Adaptors from './adaptors'
import parseInput from './input'
import { removeQueryParam, getQueryParam } from './helpers/query.string'
import getFeatureFlags, {
  isAutomaticAccountLoginEnabled
} from './featureflags'

const POPOVER_HEIGHT = 300

const input = parseInput(window.location.search)
const integration = input.mode || input.integration

const error = getQueryParam('error')
if (error) {
  removeQueryParam('error')
}
const org = getQueryParam('org')

const getAccountFeatureFlags = (accountId) =>
  accountId ? getFeatureFlags(accountId) : Promise.resolve([])

getAccountFeatureFlags(accountId).then((featureFlags) => {
  Adaptors.getAdaptor(
    integration,
    null,
    [],
    {},
    {
      onBeforeVendorReady (adaptor) {
        adaptor.showPopover(POPOVER_HEIGHT)
        login(
          integration,
          error,
          org,
          isAutomaticAccountLoginEnabled(featureFlags) ? accountName : ''
        )
      }
    },
    'electron',
    input.useGeneric
  )

  console.log('Welcome to the Azores CTI')
})
