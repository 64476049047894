import Adaptor, { PLATFORM_CHROME } from './adaptor'
import getOmnichannel, {
  isOmniChannelEnabled, OMNICHANNEL_SOURCE,
  OMNICHANNEL_SALESFORCE_EVENTS, OMNICHANNEL_RULES_ACTIONS
} from './salesforce/omnichannel'
import {
  isValidContactId, formatTo18, getContactId, request,
  VisibilityController
} from './salesforce'
import getApex, { textRequestParams, jsonRequestParams } from './salesforce/apex'
import { logMessage } from '../helpers/log'
import { sendRtmEvent } from '../service/requests'
import { RTM_CTI_TO_TALKDESK_TOPIC, RTM_SCOPE, SEND_METADATA, PRESENCE_CHANNEL_OCCUPIED } from '../callbar/providers/constants'
import { CTI_NOTIFY_SALESFORCE_ON_LOGIN, CTI_RTM_OVER_PUSHER } from '../featureflags/whitelist'

const OFFLINE_DELAY = 20000
const RELATE_OPTION_TIMEOUT = 15000
const TD_OFFLINE_STATUS = 'offline'

export const checkSuccess = (callback) => (response) => { callback(response.result) }
export const isVisible = (callback) => (response) => {
  if (response.error) return

  const isVisible = response.result

  callback(isVisible)
}

const apexRequests = {
  getExternalId: () => textRequestParams({
    apexClass: '__NAMESPACE__.CtiInteractionsService',
    methodName: 'getCurrentUserId',
    methodParams: ''
  }),
  getClickToCallKeyPrefixes: () => jsonRequestParams({
    apexClass: '__NAMESPACE__.CtiInteractionsService',
    methodName: 'getClickToCallKeyPrefixes',
    methodParams: '',
    cache: true
  }),
  getRelateOptions: (agentId, contactPhone, callStartTime, primaryTabObjectId, subTabObjectId, ui) => textRequestParams({
    apexClass: '__NAMESPACE__.CtiInteractionsService',
    methodName: 'getRelatedCaseOrOpportunity',
    methodParams: `ui=${ui}&primaryId=${primaryTabObjectId}&secondaryId=${subTabObjectId}&contactNumber=${contactPhone}&agent=${agentId}&startTime=${callStartTime}`
  }),
  getRelateOptionsForDigitalChannels: (data) => textRequestParams({
    apexClass: '__NAMESPACE__.CtiInteractionsService',
    methodName: 'getDCERelatedCaseOrOpportunity',
    methodParams: `input=${data}`
  }),
  salesforceStartCall: (objectId, objectName, phoneNumber, outboundPhoneNumber) => textRequestParams({
    apexClass: '__NAMESPACE__.CtiInteractionsService',
    methodName: 'startCall',
    methodParams: `objectId=${objectId}&objectName=${objectName}&phoneNumber=${phoneNumber}&outboundPhoneNumber=${outboundPhoneNumber}`
  }),
  hvsOnWorkStart: (workId, completeWorkWhen, phoneNumber) => textRequestParams({
    apexClass: '__NAMESPACE__.CtiInteractionsService',
    methodName: 'hvsOnWorkStart',
    methodParams: `workId=${workId}&completeWorkWhen=${completeWorkWhen}&phoneNumber=${phoneNumber}`
  }),
  getNextOmniStatus: (agentContext) => textRequestParams({
    apexClass: '__NAMESPACE__.OmniSyncService',
    methodName: 'getNextStatus',
    methodParams: `contextJson=${agentContext}`
  }),
  salesforceOnCtiLogin: () => textRequestParams({
    apexClass: '__NAMESPACE__.CtiInteractionsService',
    methodName: 'onCtiLoginHandler',
    methodParams: ''
  })
}

class SalesforceAdaptor extends Adaptor {
  constructor (...args) {
    super('salesforce', ...args)
    this.log = logMessage('SalesforceAdapter')
  }

  importVendor () {
    const vendor = require('lib/sforce').default
    require('lib/sforce.console')

    return Promise.resolve(vendor)
  }

  getExternalId (vendor) {
    const apexRequest = apexRequests.getExternalId()
    return this.runApex(apexRequest).catch(() => {
      // If apex request fails, we do one retry
      return this.runApex(apexRequest)
    })
  }

  getVendorCapabilities (vendor) {
    return new Promise((resolve, reject) => {
      isOmniChannelEnabled(vendor, this.runApex)
        .then(presence => {
          resolve({ presence })
        })
    })
  }

  notifyLoginToSalesforce () {
    const apexRequest = apexRequests.salesforceOnCtiLogin()
    this.runApex(apexRequest).catch(() => {
      console.warn('Unable to notify login to T4SF')
    })
  }

  /*
  * Hooks implementation
  */
  onVendorReady (vendor) {
    this.runApex = getApex(vendor)
    vendor.interaction.cti.enableClickToDial()
  }

  onAdaptorReady (vendor, mp, capabilities) {
    this.initTimestamp = Date.now()
    vendor.interaction.cti.onClickToDial((event) => {
      this.clickToCall(event, mp)
    })

    this.visibilityController = new VisibilityController(
      (callback) => {
        this.checkIsVisible(callback, vendor)
      },
      (newVisibility, callback) => {
        this.setVisible(newVisibility, callback, vendor)
      })

    this.visibilityController.onVisibilityChange((isCtiVisible) => {
      this.changeCallbarVisibility(isCtiVisible, mp)
    })

    if (capabilities.presence) {
      this._setOmnichannelListeners(vendor, mp)
    }

    if (this.isFeatureActive(CTI_NOTIFY_SALESFORCE_ON_LOGIN)) {
      this.notifyLoginToSalesforce()
    }
  }

  onOpenContact (contact, vendor) {
    const salesforceId = getContactId(contact)

    vendor.interaction.screenPop(salesforceId, false)
  }

  onConnect (data, vendor, mp, capabilities) {
    const useOmnichannel = capabilities.presence

    if (data.propagateStatus) {
      if (this.isFeatureActive(CTI_RTM_OVER_PUSHER)) {
        sendRtmEvent(RTM_CTI_TO_TALKDESK_TOPIC, RTM_SCOPE, this.sessionInfo.userId, SEND_METADATA, {
          type: PRESENCE_CHANNEL_OCCUPIED,
          omnichannel: !!useOmnichannel
        })
      }

      if (useOmnichannel) {
        mp.integrationStatusChange({
          status: this.omnichannel.currentStatus,
          source: OMNICHANNEL_SOURCE
        })
      }
    }

    useOmnichannel && this.stopDisconnectionTimeout()
  }

  onDisconnect (data, vendor, mp, capabilities) {
    if (this._disconnectTimeout || !capabilities.presence) { return }

    this._disconnectTimeout = setTimeout(() => {
      this.stopDisconnectionTimeout()

      if (capabilities.presence) {
        this.updateOmniAgentContext(OMNICHANNEL_SALESFORCE_EVENTS.TD_AGENT_LOGOUT, TD_OFFLINE_STATUS)
        this.changePresenceStatusByOmniRules()
      }

      this.omnichannel.setPresenceStatus({
        status: 'offline'
      })
    }, OFFLINE_DELAY)
  }

  onRequestInteractionExternalId (data, vendor, mp) {
    return this.getCaseIdForCurrentPage(vendor, mp)
  }

  onLoginSuccess (options, vendor, mp, capabilities) {
    const data = options.data

    if (!capabilities.presence) {
      return options.callback({
        presence: undefined
      })
    }

    if (!this.omnichannel) {
      this.getOmnichannelPromise = this.getOmnichannelPromise.then(
        () => this.onLoginSuccess(options, vendor, mp, capabilities)
      )

      return
    }

    if (this.initTimestamp > data.loginSuccessTs) {
      if (data.status && (!this.omnichannel.currentStatus ||
          this.omnichannel.currentStatus !== data.status)) {
        this.omnichannel.setPresenceStatus(data)
      }
    } else if (this.omnichannel.currentStatus &&
      this.omnichannel.currentStatus !== data.status) {
      mp.integrationStatusChange({
        status: this.omnichannel.currentStatus,
        source: OMNICHANNEL_SOURCE
      })
    }

    options.callback({
      presence: this.omnichannel.getLogoutSettings()
    })
  }

  onAgentStatusChanged (data, vendor, mp, capabilities) {
    if (capabilities.presence) {
      this.changePresenceStatus(data)

      this.updateOmniAgentContext(OMNICHANNEL_SALESFORCE_EVENTS.TD_AGENT_STATUS_CHANGED, data.status)

      if (this.omnichannel && !this.omnichannel.newStatusBasedOnSaved) {
        this.changePresenceStatusByOmniRules()
      }
    }
  }

  async onRequestRelatedOptionsByChannel (data, vendor, mp) {
    switch (data.channel) {
      case 'voice':
        return await this.onFetchRelateOptions(data, vendor, mp)
      case 'sms':
      case 'whatsapp':
        return await this.onRequestRelateToOptionsForDigitalChannels(data, vendor, mp)
      default:
        throw new Error('The ' + data.channel + ' channel is not available')
    }
  }

  onFetchRelateOptions (data, vendor, mp) {
    return request(new Promise((resolve, reject) => {
      const dataFetchingSteps = [
        this.getExternalId(vendor),
        this.getPrimaryTabObject(vendor),
        this.getSubTabObject(vendor)
      ]

      Promise.all(dataFetchingSteps).then((result) => {
        const agentId = result[0]
        const primaryTabObjectId = result[1]
        const subTabObjectId = result[2]
        const contactPhone = data.phoneNumber
        const callStartTime = data.callStartTimestamp
        const ui = vendor.console.isInConsole() ? 'console' : ''

        const apexRequest = apexRequests.getRelateOptions(
          agentId,
          contactPhone,
          callStartTime,
          primaryTabObjectId,
          subTabObjectId,
          ui
        )

        this.runApex(apexRequest).then((result) => {
          const parsedResult = JSON.parse(result)
          resolve(parsedResult)
        }).catch(() => {
          resolve([])
        })
      })
    }), RELATE_OPTION_TIMEOUT)
  }

  onRequestRelateToOptionsForDigitalChannels (data, vendor, mp) {
    return request(new Promise((resolve, reject) => {
      Promise.all([
        this.getPrimaryTabObject(vendor),
        this.getSubTabObject(vendor)
      ]).then((values) => {
        const input = {
          ...data,
          pageInfo: {
            ui: vendor.console.isInConsole() ? 'console' : '',
            primaryTabId: values[0],
            secondaryTabId: values[1]
          }
        }

        const apexRequest = apexRequests.getRelateOptionsForDigitalChannels(JSON.stringify(input))
        this.runApex(apexRequest).then((result) => {
          const response = { ...JSON.parse(result), channel: data.channel }
          resolve(response)
        }).catch((error) => {
          console.log('An error occurred getting the relate options for SMS and Whatsapp')
          console.log(error)
          const responseError = { success: false, options: [], onFocus: null, errorMessage: error, channel: data.channel }
          resolve(responseError)
        })
      })
    }), RELATE_OPTION_TIMEOUT)
  }

  onOpenTicket (externalId, vendor) {
    vendor.interaction.screenPop('/' + externalId)
  }

  /*
  * Internal API
  */

  _setOmnichannelListeners (vendor, mp) {
    this.getOmnichannelPromise = getOmnichannel(vendor, this.runApex).then((omnichannel) => {
      this.omnichannel = omnichannel
      const omniCallback = () => {
        this.changePresenceStatusByOmniRules()
      }

      this.omnichannel.onPresenceStatusChanged((data) => {
        this.changePresenceStatusByOmniRules()
        this.presenceStatusChanged(data, mp)
      })

      this.omnichannel.onLogout((data) => {
        this.changePresenceStatusByOmniRules()
        this.omnichannelLogout(data, mp)
      })

      this.omnichannel.onWorkItemAccepted(omniCallback)

      this.omnichannel.onWorkItemRejected(omniCallback)

      this.omnichannel.onWorkItemClosed(omniCallback)

      this.omnichannel.onWorkloadChangedAndCapacity(omniCallback)
    })
  }

  changePresenceStatus (data) {
    if (!this.omnichannel) {
      this.getOmnichannelPromise = this.getOmnichannelPromise.then(
        () => this.changePresenceStatus(data)
      )

      return
    }

    this.omnichannel.setPresenceStatus(data)
  }

  updateOmniAgentContext (eventName, newStatus) {
    if (this.omnichannel && this.omnichannel.agentContext) {
      this.omnichannel.agentContext = {
        ...this.omnichannel.agentContext,
        event: eventName,
        newTalkdeskStatus: newStatus,
        oldTalkdeskStatus: this.omnichannel.agentContext.newTalkdeskStatus,
        oldWorkload: this.omnichannel.agentContext.newWorkload,
        oldCapacity: this.omnichannel.agentContext.newCapacity,
        oldPercWorkload: this.omnichannel.agentContext.newPercWorkload,
        oldSalesforceStatus: this.omnichannel.agentContext.newSalesforceStatus
      }
      if (this.omnichannel.newStatusBasedOnSaved) {
        this.omnichannel.agentContext = {
          ...this.omnichannel.agentContext,
          newSalesforceStatus: this.omnichannel.agentContext.savedSalesforceStatus,
          savedSalesforceStatus: ''
        }
      }
    }
  }

  changePresenceStatusByOmniRules () {
    if (this.omnichannel && this.omnichannel.agentContext) {
      const apexRequest = apexRequests.getNextOmniStatus(
        JSON.stringify(this.omnichannel.agentContext)
      )
      this.runApex(apexRequest).then((result) => {
        const parsedResult = JSON.parse(result)
        if (parsedResult && parsedResult.action === OMNICHANNEL_RULES_ACTIONS.SF_CHANGE_AGENT_STATUS) {
          this.omnichannel.setPresenceStatusOmniRules({
            sfStatusId: parsedResult.status
          })
        }
      }).catch((error) => {
        this.log(error.message)
      })
    }
  }

  presenceStatusChanged (data, mp) {
    this.stopDisconnectionTimeout()

    mp.integrationStatusChange(data)
  }

  omnichannelLogout (data, mp) {
    mp.integrationStatusChange(data)
  }

  stopDisconnectionTimeout () {
    clearTimeout(this._disconnectTimeout)

    this._disconnectTimeout = null
  }

  clickToCall (event, mp) {
    const info = this.mapClickToCallEvent(event)
    const objectId = info.recordId
    const message = { number: info.number, name: info.objectName || info.recordName }
    const apexRequest = apexRequests.salesforceStartCall(objectId, message.name, message.number, '')
    this.runApex(apexRequest).catch(() => {
      Promise.resolve(isValidContactId(objectId))
        .then((isValid) => isValid || this.isValidContactIdApex(objectId))
        .then((isValid) => {
          if (isValid) {
            mp.dialContact({ ...message, externalId: formatTo18(objectId) })
          } else {
            mp.dialContact(message)
          }
        })
    })
  }

  mapClickToCallEvent (event) {
    const result = event.result ? JSON.parse(event.result) : event
    const recordId = result.personAccount ? result.contactId : result.objectId || result.recordId
    const info = { ...result, recordId }

    return info
  }

  checkIsVisible (callback, vendor) {
    vendor.interaction.isVisible(isVisible(callback))
  }

  changeCallbarVisibility (isCtiVisible, mp) {
    mp.ctiVisibility({ isVisible: isCtiVisible })
  }

  updateVisibility (newVisibility) {
    this.visibilityController.updateVisibility(newVisibility)
  }

  setVisible (newVisibility, callback, vendor) {
    vendor.interaction.setVisible(newVisibility, checkSuccess(callback))
  }

  applyHeight (height, vendor) {
    vendor.interaction.cti.setSoftphoneHeight(height)
  }

  showCTIPopover (vendor, height = 250) {
    this.applyHeight(height, vendor)
    if (vendor.console.isInConsole()) {
      this.setVisible(true, () => {}, vendor)
    }
  }

  hideCTIPopover (vendor) {
    if (vendor.console.isInConsole()) {
      this.setVisible(false, () => {}, vendor)
    } else if (this.platform === PLATFORM_CHROME) {
      this.applyHeight(0, vendor)
    }
  }

  getCaseIdForCurrentPage (vendor, mp) {
    return new Promise((resolve, reject) => {
      this.getPageInfo(vendor, (payload) => {
        if (payload.error) {
          return reject(new Error(payload.error))
        }

        let result

        try {
          result = JSON.parse(payload.result)
        } catch (e) {
          return reject(new Error('Error parsing getPageInfo result.'))
        }

        if (this.isObjectCase(result)) {
          resolve({ [this.name]: formatTo18(result.objectId) })
        } else {
          reject(new Error('Salesforce page is not a Case.'))
        }
      })
    })
  }

  isValidContactIdApex (objectId) {
    return request(new Promise((resolve, reject) => {
      if (!objectId) { resolve(false) }
      const apexRequest = apexRequests.getClickToCallKeyPrefixes()
      this.runApex(apexRequest).then((prefixes) => {
        return resolve(isValidContactId(objectId, prefixes))
      }).catch(() => {
        return resolve(false)
      })
    }))
  }

  isObjectCase (data) {
    if (data && data.id) {
      // if the id starts with 500 then it is a case.
      return data.id.startsWith('500')
    } else if (data && data.object) {
      return data.object === 'Case'
    }

    return false
  }

  getPrimaryTabObject (vendor) {
    return new Promise((resolve, reject) => {
      if (!vendor.console.isInConsole()) {
        resolve(null)
        return
      }

      vendor.console.getFocusedPrimaryTabObjectId((result) => {
        const objectId = (result.success) ? result.id : null
        resolve(objectId)
      })
    })
  }

  getSubTabObject (vendor) {
    return new Promise((resolve, reject) => {
      if (!vendor.console.isInConsole()) {
        resolve(null)
        return
      }

      vendor.console.getFocusedSubtabObjectId((result) => {
        const objectId = (result.success) ? result.id : null
        resolve(objectId)
      })
    })
  }

  getPrimaryPageInfo (vendor, callback) {
    vendor.console.getFocusedPrimaryTabId((payload) => {
      if (!payload.success) {
        return vendor.interaction.getPageInfo(callback)
      }

      vendor.console.getPageInfo(payload.id, (pagePayload) => {
        callback({ result: pagePayload.pageInfo })
      })
    })
  }

  getPrimaryTabInfo (vendor, callback) {
    vendor.console.getFocusedPrimaryTabObjectId((result) => {
      if (result.success && this.isObjectCase(result)) {
        const jsonResult = JSON.stringify({
          objectId: result.id,
          object: 'Case'
        })

        callback({ result: jsonResult })
      } else {
        this.getPrimaryPageInfo(vendor, callback)
      }
    })
  }

  getSubTabPageInfo (vendor, callback) {
    vendor.console.getFocusedSubtabId((payload) => {
      if (!payload.success) {
        return this.getPrimaryTabInfo(vendor, callback)
      }

      vendor.console.getPageInfo(payload.id, (subTabPayload) => {
        let result

        try {
          result = JSON.parse(subTabPayload.pageInfo)
        } catch (e) {
          return this.getPrimaryTabInfo(vendor, callback)
        }

        if (this.isObjectCase(result)) {
          callback({ result: subTabPayload.pageInfo })
        } else {
          this.getPrimaryTabInfo(vendor, callback)
        }
      })
    })
  }

  getSubTabInfo (vendor, callback) {
    vendor.console.getFocusedSubtabObjectId((result) => {
      if (result.success && this.isObjectCase(result)) {
        const jsonResult = JSON.stringify({
          objectId: result.id,
          object: 'Case'
        })

        callback({ result: jsonResult })
      } else {
        this.getSubTabPageInfo(vendor, callback)
      }
    })
  }

  getPageInfo (vendor, callback) {
    if (!vendor.console.isInConsole()) {
      return vendor.interaction.getPageInfo(callback)
    }

    this.getSubTabInfo(vendor, callback)
  }

  onWorkStart (event) {
    const result = event.result ? JSON.parse(event.result) : event
    if (result.workId && result.workId !== 'NO_WORK_ID') {
      const apexRequest = apexRequests.hvsOnWorkStart(result.workId, result.completeWorkWhen, result.attributes.to)
      this.runApex(apexRequest).catch(() => {
      // retry
        this.runApex(apexRequest)
      })
    }
  }
}

export default SalesforceAdaptor