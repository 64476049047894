import { logMessage } from '../../helpers/log'
import { jsonRequestParams } from './apex'
import SalesforceConsole from './console'

const TD_OFFLINE_STATUS = 'offline'
const TD_BUSY_STATUS = 'busy'
const TD_ACW_STATUS = 'after_call_work'
const OMNI_AGENT_DEFAULT_CONTEXT = '0'
const OMNICHANNEL_OFFLINE_STATUS_NAME = 'offline'
export const OMNICHANNEL_AGENT_CONTEXT_DEFAULT = {
  newSalesforceStatus: OMNICHANNEL_OFFLINE_STATUS_NAME,
  oldSalesforceStatus: OMNICHANNEL_OFFLINE_STATUS_NAME,
  newTalkdeskStatus: TD_OFFLINE_STATUS,
  oldTalkdeskStatus: TD_OFFLINE_STATUS,
  newWorkload: OMNI_AGENT_DEFAULT_CONTEXT,
  oldWorkload: OMNI_AGENT_DEFAULT_CONTEXT,
  newCapacity: OMNI_AGENT_DEFAULT_CONTEXT,
  oldCapacity: OMNI_AGENT_DEFAULT_CONTEXT,
  newPercWorkload: OMNI_AGENT_DEFAULT_CONTEXT,
  oldPercWorkload: OMNI_AGENT_DEFAULT_CONTEXT,
  serviceChannel: []
}
export const OMNICHANNEL_SALESFORCE_EVENTS = {
  INIT_AGENT_WORKLOAD: 'init_Agent_Workload',
  SF_WORKITEM_ACCEPTED: 'Salesforce_work_item_accepted',
  SF_WORKITEM_REJECTED: 'Salesforce_work_item_rejected',
  SF_WORKITEM_CLOSED: 'Salesforce_work_item_closed',
  SF_WORKLOAD_CHANGED: 'Salesforce_Workload_change',
  SF_CAPACITY_CHANGED: 'Agent_capacity_change',
  SF_AGENT_STATUS_CHANGED: 'Salesforce_Agent_Status_Change',
  SF_AGENT_LOGOUT: 'Salesforce_Agent_Logout',
  TD_AGENT_STATUS_CHANGED: 'Talkdesk_Agent_status_change',
  TD_AGENT_LOGOUT: 'Talkdesk_Agent_Logout'
}
export const OMNICHANNEL_RULES_ACTIONS = {
  SF_CHANGE_AGENT_STATUS: 'Change_Salesforce_Agent_Status'
}
export const OMNICHANNEL_SOURCE = 'Omni-Channel'
export const OMNICHANNEL_OFFLINE_STATUS_ID = '0N5000000000FFF'

export class OmnichannelBase {
  hasPresenceSupport () { return false }
  getLogoutSettings () { return undefined }
  setPresenceStatus () {}
  onPresenceStatusChanged () {}
  onLogout () {}
  onWorkItemAccepted () {}
  onWorkItemRejected () {}
  onWorkItemChanged () {}
  onWorkItemClosed () {}
  onWorkloadChangedAndCapacity () {}
  onRevertTalkdeskAgentStatus () {}
}

export const ctiInteractionsServiceRequest = (data) => jsonRequestParams({
  apexClass: '__NAMESPACE__.CtiInteractionsService',
  ...data
})

const apexRequests = {
  getOmniChannelStatus: (statusCode) => ctiInteractionsServiceRequest({
    methodName: 'getOmniChannelStatuses',
    methodParams: `talkdeskStatusId=${statusCode}`
  }),
  getTalkdeskStatus: (statusId) => ctiInteractionsServiceRequest({
    methodName: 'getTalkdeskStatuses',
    methodParams: `omniChannelStatusId=${statusId}`
  }),
  getOmniChannelSettings: (statusId) => ctiInteractionsServiceRequest({
    methodName: 'getOmniChannelSettings',
    methodParams: `omniChannelStatusId=${statusId}`
  }),
  isOmniChannelEnabled: () => ctiInteractionsServiceRequest({
    methodName: 'isOmniChannelEnabled',
    methodParams: ''
  })
}

export class OmnichannelPresence extends OmnichannelBase {
  constructor (sfConsole, runApex) {
    super()
    this.sfConsole = sfConsole
    this.runApex = runApex
    this.log = logMessage('Omnichannel')
  }

  init () {
    this.currentOmnichannelStatus = OMNICHANNEL_OFFLINE_STATUS_ID
    this.currentOmnichannelStatusApiName = OMNICHANNEL_OFFLINE_STATUS_NAME
    return this._initOmnichannel()
  }

  getOfflineSettings (statusCode) {
    return {
      status: statusCode || TD_OFFLINE_STATUS,
      source: OMNICHANNEL_SOURCE
    }
  }

  getLogoutSettings () {
    return this.logoutSettings || this.getOfflineSettings()
  }

  hasPresenceSupport () { return true }

  getOmniChannelStatus (statusCode) {
    this.log('getOmniChannelStatus', statusCode)

    const apexRequest = apexRequests.getOmniChannelStatus(statusCode)
    return this.runApex(apexRequest).catch((error) => {
      this.log('getOmniChannelStatus error', error)
      return { description: 'getOmniChannelStatus failed', error }
    })
  }

  getTalkdeskStatus (statusId) {
    this.log('getTalkdeskStatus', statusId)

    const apexRequest = apexRequests.getTalkdeskStatus(statusId)
    return this.runApex(apexRequest).catch((error) => {
      return { description: 'getTalkdeskStatus failed', error }
    })
  }

  getOmniChannelSettings (statusId) {
    this.log('getOmniChannelSettings')

    const apexRequest = apexRequests.getOmniChannelSettings(statusId)
    return this.runApex(apexRequest).then((result) => {
      return { success: true, talkdeskStatuses: result.talkdeskStatuses, isLastOCStatusEnabled: result.isLastOCStatusEnabled }
    }).catch((error) => {
      console.warn(error)
      return this.getTalkdeskStatus(statusId).then((result) => {
        return { success: false, id: result.id }
      })
    })
  }

  setPresenceStatusOmniRules (data) {
    this.log('setPresenceStatusOmniRule', data)
    const sfStatusId = this.getSalesforce15Id(data.sfStatusId)

    this.currentOmnichannelStatus = sfStatusId
    this.updatedByOmniSyncRules = true

    this.updateOmniStatus(sfStatusId)
  }

  setPresenceStatus (data) {
    this.log('setStatus', data)
    const status = data.status

    if (this.currentStatus === status) {
      return
    }

    if (this.checkGoBackToLastStatus(status)) {
      const newSFStatusId = this.agentContext.savedSalesforceStatus
      this.updateOmniStatus(this.getSalesforce15Id(newSFStatusId))
      this.newStatusBasedOnSaved = true
      return
    }
    this.newStatusBasedOnSaved = false

    this.getOmniChannelStatus(status).then((result) => {
      if (!result || result.error || this.currentStatus === status) {
        return
      }

      if (!result.id) {
        this.revertTalkdeskAgentStatus(this.currentOmnichannelStatus)
        return
      }

      const mappedOmnichannelStatus = this.getSalesforce15Id(result.id)

      if (this.currentOmnichannelStatus === mappedOmnichannelStatus) {
        return
      }

      this.currentStatus = status
      this.currentOmnichannelStatus = mappedOmnichannelStatus

      this.updateOmniStatus(mappedOmnichannelStatus)
    })
  }

  revertTalkdeskAgentStatus (currentOmnichannelStatus) {
    if (this.eventTalkdeskAgentStatusCallback) {
      this.eventTalkdeskAgentStatusCallback(currentOmnichannelStatus)
    }
  }

  checkGoBackToLastStatus (status) {
    if (this.isLastStatusOmniEnabled) {
      const callIsStarting = (status === TD_BUSY_STATUS && this.agentContext.newTalkdeskStatus !== TD_BUSY_STATUS)

      if (callIsStarting) {
        this.agentContext.savedSalesforceStatus = this.currentOmnichannelStatus
      }

      const callIsEnding = (
        (
          (status !== TD_BUSY_STATUS && status !== TD_ACW_STATUS && this.agentContext.newTalkdeskStatus === TD_BUSY_STATUS) ||
          (status !== TD_BUSY_STATUS && status !== TD_ACW_STATUS && this.agentContext.newTalkdeskStatus === TD_ACW_STATUS)
        ) &&
        this.agentContext.savedSalesforceStatus !== '')

      if (callIsEnding) {
        return true
      }
    }
    return false
  }

  updateOmniStatus (mappedOmnichannelStatus) {
    if (mappedOmnichannelStatus === OMNICHANNEL_OFFLINE_STATUS_ID) {
      this.sfConsole.logout()
    } else {
      this.sfConsole.setServicePresenceStatus(mappedOmnichannelStatus)
    }
  }

  getSalesforce15Id (sfId) {
    return sfId.substring(0, 15)
  }

  buildAgentContext () {
    this.agentContext = OMNICHANNEL_AGENT_CONTEXT_DEFAULT

    const steps = [
      this.getAgentWorkload(),
      this.getServicePresenceStatusChannels()
    ]

    return Promise.all(steps).then(([agentWorkload, agentChannels]) => {
      const sfStatus = this.currentOmnichannelStatusApiName
      const tdStatus = this.currentStatus
      const currentWorkload = agentWorkload.currentWorkload
      const configuredCapacity = agentWorkload.configuredCapacity
      const channels = agentChannels.channels
      const percWorkload = String(this.getPercWorkload(currentWorkload, configuredCapacity))

      this.agentContext = {
        ...this.agentContext,
        event: OMNICHANNEL_SALESFORCE_EVENTS.INIT_AGENT_WORKLOAD,
        savedSalesforceStatus: '',
        newSalesforceStatus: sfStatus,
        oldSalesforceStatus: sfStatus,
        newTalkdeskStatus: tdStatus,
        oldTalkdeskStatus: tdStatus,
        newWorkload: currentWorkload,
        oldWorkload: currentWorkload,
        newCapacity: configuredCapacity,
        oldCapacity: configuredCapacity,
        newPercWorkload: percWorkload,
        oldPercWorkload: percWorkload,
        serviceChannel: channels
      }
    })
  }

  getPercWorkload (current, capacity) {
    return Math.round((current / capacity) * 100 || 0)
  }

  getAgentWorkload () {
    return new Promise(resolve => {
      const eventCallback = (result) => {
        if (!result.success) {
          resolve({ currentWorkload: OMNI_AGENT_DEFAULT_CONTEXT, configuredCapacity: OMNI_AGENT_DEFAULT_CONTEXT })
        } else {
          resolve(result)
        }
      }
      this.sfConsole.getAgentWorkload(eventCallback)
    })
  }

  getServicePresenceStatusChannels () {
    return new Promise(resolve => {
      const eventCallback = (result) => {
        const channels = result && result.channels && JSON.parse(result.channels).map((obj) => obj.developerName)
        if (!channels) {
          resolve({ channels: [] })
        } else {
          resolve({ channels })
        }
      }
      this.sfConsole.getServicePresenceStatusChannels(eventCallback)
    })
  }

  onWorkItemAccepted (callback) {
    this.log('susbcribe to Omnichannel onWorkItemAccepted')
    const eventCallback = (result) => {
      this.agentContext.event = OMNICHANNEL_SALESFORCE_EVENTS.SF_WORKITEM_ACCEPTED
      callback()
    }
    this.sfConsole.onWorkItemAccepted(eventCallback)
  }

  onWorkItemRejected (callback) {
    this.log('susbcribe to Omnichannel onWorkItemRejected')
    const eventCallback = (result) => {
      this.getAgentWorkload().then((values) => {
        const { currentWorkload, configuredCapacity } = values
        const percWorkload = String(this.getPercWorkload(currentWorkload, configuredCapacity))
        this.agentContext = {
          ...this.agentContext,
          event: OMNICHANNEL_SALESFORCE_EVENTS.SF_WORKITEM_REJECTED,
          newWorkload: currentWorkload,
          oldWorkload: this.agentContext.newWorkload,
          newCapacity: configuredCapacity,
          oldCapacity: this.agentContext.newCapacity,
          newPercWorkload: percWorkload,
          oldPercWorkload: this.agentContext.newPercWorkload,
          oldSalesforceStatus: this.agentContext.newSalesforceStatus,
          oldTalkdeskStatus: this.agentContext.newTalkdeskStatus
        }
        callback()
      })
    }
    this.sfConsole.onWorkItemRejected(eventCallback)
  }

  onWorkItemClosed (callback) {
    this.log('susbcribe to Omnichannel onWorkItemClosed')
    const eventCallback = (result) => {
      this.getAgentWorkload().then((values) => {
        const { currentWorkload, configuredCapacity } = values
        const percWorkload = String(this.getPercWorkload(currentWorkload, configuredCapacity))
        this.agentContext = {
          ...this.agentContext,
          event: OMNICHANNEL_SALESFORCE_EVENTS.SF_WORKITEM_CLOSED,
          newWorkload: currentWorkload,
          oldWorkload: this.agentContext.newWorkload,
          newCapacity: configuredCapacity,
          oldCapacity: this.agentContext.newCapacity,
          newPercWorkload: percWorkload,
          oldPercWorkload: this.agentContext.newPercWorkload,
          oldSalesforceStatus: this.agentContext.newSalesforceStatus,
          oldTalkdeskStatus: this.agentContext.newTalkdeskStatus
        }
        callback()
      })
    }
    this.sfConsole.onWorkItemClosed(eventCallback)
  }

  onWorkloadChangedAndCapacity (callback) {
    this.log('susbcribe to Omnichannel onWorkloadChangedAndCapacity')
    const eventCallback = (result) => {
      const eventName = (result.newWorkload === result.previousWorkload) ? OMNICHANNEL_SALESFORCE_EVENTS.SF_CAPACITY_CHANGED : OMNICHANNEL_SALESFORCE_EVENTS.SF_WORKLOAD_CHANGED
      const newPerWorkload = String(this.getPercWorkload(result.newWorkload, result.configuredCapacity))
      const oldPerWorkload = String(this.getPercWorkload(result.previousWorkload, result.configuredCapacity))
      this.agentContext = {
        ...this.agentContext,
        event: eventName,
        newCapacity: result.configuredCapacity,
        oldCapacity: this.agentContext.newCapacity,
        newWorkload: result.newWorkload,
        oldWorkload: result.previousWorkload,
        newPercWorkload: newPerWorkload,
        oldPercWorkload: oldPerWorkload,
        oldSalesforceStatus: this.agentContext.newSalesforceStatus,
        oldTalkdeskStatus: this.agentContext.newTalkdeskStatus
      }
      callback()
    }
    this.sfConsole.onWorkloadChangedAndCapacity(eventCallback)
  }

  onLogout (callback) {
    this.sfConsole.onLogout(() => {
      this.log('Logout', this.currentStatus)

      if (this.currentStatus !== this.logoutSettings.status) {
        this.currentStatus = this.logoutSettings.status
        this.currentOmnichannelStatus = OMNICHANNEL_OFFLINE_STATUS_ID
        this.currentOmnichannelStatusApiName = OMNICHANNEL_OFFLINE_STATUS_NAME

        this.agentContext = {
          ...this.agentContext,
          event: OMNICHANNEL_SALESFORCE_EVENTS.SF_AGENT_LOGOUT,
          newSalesforceStatus: this.currentOmnichannelStatusApiName,
          oldSalesforceStatus: this.agentContext.newSalesforceStatus,
          oldTalkdeskStatus: this.agentContext.newTalkdeskStatus,
          oldWorkload: this.agentContext.newWorkload,
          oldCapacity: this.agentContext.newCapacity,
          oldPercWorkload: this.agentContext.newPercWorkload
        }
        callback(this.getLogoutSettings())
      }
    })
  }

  onPresenceStatusChanged (callback) {
    this.log('susbcribe to Omnichannel onPresenceStatusChanged')

    const eventCallback = (data) => {
      const { statusId, statusApiName, channels } = data
      if (!this.updatedByOmniSyncRules && this.currentOmnichannelStatus === statusId) {
        this.agentContext = {
          ...this.agentContext,
          serviceChannel: channels,
          newSalesforceStatus: statusApiName,
          oldSalesforceStatus: statusApiName
        }
        return
      }

      this.getTalkdeskStatus(statusId).then((result) => {
        const status = result.id

        const talkdeskStatusChanged = this.currentStatus !== status
        const salesforceStatusChanged = this.currentOmnichannelStatus !== statusId

        if (result.error ||
          (!this.updatedByOmniSyncRules &&
            !talkdeskStatusChanged && !salesforceStatusChanged)) {
          return
        }

        this.currentStatus = status
        this.currentOmnichannelStatus = statusId
        this.currentOmnichannelStatusApiName = statusApiName
        this.updatedByOmniSyncRules = false

        this.agentContext = {
          ...this.agentContext,
          event: OMNICHANNEL_SALESFORCE_EVENTS.SF_AGENT_STATUS_CHANGED,
          serviceChannel: channels,
          newSalesforceStatus: this.currentOmnichannelStatusApiName,
          oldSalesforceStatus: this.agentContext.newSalesforceStatus,
          oldTalkdeskStatus: this.agentContext.newTalkdeskStatus,
          oldWorkload: this.agentContext.newWorkload,
          oldCapacity: this.agentContext.newCapacity,
          oldPercWorkload: this.agentContext.newPercWorkload
        }

        callback({
          status: result.id,
          source: OMNICHANNEL_SOURCE,
          description: result.label
        })
      })
    }

    this.sfConsole.onPresenceStatusChanged(eventCallback)
  }

  onRevertTalkdeskAgentStatus (callback) {
    this.eventTalkdeskAgentStatusCallback = (data) => {
      this.getTalkdeskStatus(data).then((result) => {
        if (result.error) {
          return
        }

        callback({
          status: result.id,
          source: OMNICHANNEL_SOURCE
        })
      })
    }
  }

  _setupOmnichannel (resolve, reject) {
    return new Promise((resolve, reject) => {
      this.sfConsole.getServicePresenceStatusId((result) => {
        this.log('get status', result)
        if (result.success) {
          this.currentOmnichannelStatus = result.statusId
          this.currentOmnichannelStatusApiName = result.statusApiName
          this._getOmniChannelSettings(this.currentOmnichannelStatus).then((result) => {
            resolve(result)
          })
        } else {
          this._getOmniChannelSettings(OMNICHANNEL_OFFLINE_STATUS_ID).then(() => {
            reject({ success: false })
          })
        }
      })
    }).catch((err) => {
      this.currentStatus = this.logoutSettings.status
      return err
    })
  }

  _getOCCurrentStatus () {
    return this.getTalkdeskStatus(OMNICHANNEL_OFFLINE_STATUS_ID).then((result) => {
      this.logoutSettings = this.getOfflineSettings(result.id)
      return result
    })
  }

  _getOmniChannelSettings (currentOmnichannelStatus) {
    return new Promise((resolve) => {
      this.getOmniChannelSettings(currentOmnichannelStatus).then((result) => {
        if (result.success) {
          this.currentStatus = result.talkdeskStatuses.id
          this.isLastStatusOmniEnabled = result.isLastOCStatusEnabled
          this.newStatusBasedOnSaved = false
        } else {
          this.currentStatus = result.id
        }
        resolve(result)
      })
    })
  }

  _initOmnichannel () {
    const steps = [
      this._setupOmnichannel(),
      this._getOCCurrentStatus()
    ]

    return Promise.all(steps).then((values) => {
      this.log('init', values)
      return values
    })
  }
}

export const hasPresenceSupport = (vendor) => {
  return new Promise((resolve, reject) => {
    if (!vendor) {
      return reject('vendor not available')
    }

    if (vendor.console.isInConsole()) {
      resolve(true)
    } else {
      reject('Console not available')
    }
  })
}

export const isOmniChannelEnabled = (vendor, runApex) => {
  return new Promise((resolve) => {
    return hasPresenceSupport(vendor).then(() => {
      const apexRequest = apexRequests.isOmniChannelEnabled()
      return runApex(apexRequest).then((result) => {
        if (result.isOmniChannelEnabled) {
          resolve(true)
        } else {
          resolve(false)
        }
      }).catch((error) => {
        if (error.message === '"Could not find method: isOmniChannelEnabled."') {
          /**
          * This is a code path is for backward compatibility reasons for the case
          * where the Managed Package is on a version that doesn't exposes the
          * isOmniChannelEnabled apex call (prior to having a toggle in UI).
          * If we've a go that those versions can be left out of omnichannel support
          * we can clean up this initialization code A LOT.
          */
          resolve(true)
        } else {
          resolve(false)
        }
      })
    }).catch(() => {
      resolve(false)
    })
  })
}

export const initOmnichannel = (vendor, runApex, log) => {
  return new Promise((resolve) => {
    const sfConsole = new SalesforceConsole(vendor.console, {
      numberOfRetries: 5,
      backoffValue: 50
    })
    const omnichannelPresence = new OmnichannelPresence(sfConsole, runApex)
    omnichannelPresence.init().then(() => {
      omnichannelPresence.buildAgentContext().then(() => {
        log('supported')
        resolve(omnichannelPresence)
      })
    })
  })
}

const getOmnichannel = (vendor, runApex) => {
  const log = logMessage('Omnichannel')

  return new Promise((resolve) => {
    isOmniChannelEnabled(vendor, runApex).then((result) => {
      log(`${result ? 'enabled' : 'disabled'}`)

      if (result) {
        initOmnichannel(vendor, runApex, log).then((omnichannel) => {
          resolve(omnichannel)
        })
      } else {
        resolve(new OmnichannelBase())
      }
    })
  })
}

export default getOmnichannel
